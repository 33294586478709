import React, { useEffect } from 'react';
import { useStoreContext } from '../utils/GlobalState';
import { Outlet } from 'react-router-dom';

import DasboardNav from '../components/dashboard/dashboardNav';

import { useQuery } from '@apollo/client';
import { QUERY_USER } from "../utils/queries";
import { SET_USER } from '../utils/actions';

function Dashboard() {

  //setting global state with useStoreContext and useEffect
  // eslint-disable-next-line
  const [state, dispatch] = useStoreContext();
  console.log(state)

  const { loading, data } = useQuery(QUERY_USER);

  useEffect(() => {
    if (data) {
      dispatch({
        type: SET_USER,
        user: data.user,
      });
    }
  }, [data, loading, dispatch]);


return (
  <div className='flex flex-wrap bg-[#102440] md:p-6 pl-0 h-[100vh] w-full max-w-[100vw]'>
      <DasboardNav />
      <div className='w-full lg:w-[87%] h-full bg-white rounded-lg overflow-scroll scrollbar2 overflow-x-hidden'>
      <Outlet/>
      </div>
  </div>    
  )
};

export default Dashboard;