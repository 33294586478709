import React, { useState, useEffect, useRef } from "react";
import { useStoreContext } from '../../../utils/GlobalState';
import { ImArrowLeft } from 'react-icons/im';
import { IoIosArrowForward } from "react-icons/io";
import { Link, useParams } from "react-router-dom";
import { useQuery, useMutation } from "@apollo/client";
import { QUERY_GUIDE } from "../../../utils/queries";
import { UPDATE_GUIDE_TITLE, UPDATE_GUIDE_TITLE_POI } from '../../../utils/mutations';
import { SET_GUIDE } from "../../../utils/actions";
import EditAddressComponent from "./mapAndLocations/editAddress";
import PhotoTheme from "./photoTheme";
import EditSection from "./editSection";
import DeleteGuide from "./deleteGuide";
import EditPhone from "./editPhone";
import brush from '../../../assets/brush-01.png'
import map from '../../../assets/map-02.png'
import info from '../../../assets/layout-alt-03.png'
import phone from '../../../assets/phone-01.png'
import share from '../../../assets/share-04.png'
import editGray from '../../../assets/edit-gray.png';

import apartment from '../../../assets/icons/Appartement.png';
import beach from '../../../assets/icons/Beach-House.png';
import cabin from '../../../assets/icons/Cabin.png';
import rv from '../../../assets/icons/RV.png';
import van from '../../../assets/icons/Sprinter-Van.png';
import darkLogo from '../../../assets/icons/dark512.png';
import lightLogo from '../../../assets/icons/light512.png';
import dark1 from '../../../assets/icons/dark.png';
import light1 from '../../../assets/icons/light.png';

import QRCodeModal from "../guides/qrCode";

function  EditGuide ()  {
  const [state, dispatch] = useStoreContext();
  const { guideId } = useParams();
  const subStatus = state?.user?.subscriptionStatus === 'active';

  let blur = {
    filter: 'blur(0px)'
  }
  subStatus ? (
    blur = { filter: 'blur(0px)' }
  ) : (
    blur = { filter: 'blur(1.7px)',
    pointerEvents: 'none'
    }
  )

  const [activeEdit, setActiveEdit] = useState('theme');

  const { loading, data } = useQuery(QUERY_GUIDE, {
    variables: {
      guideId: guideId
    }
  });

  //SHARE PAGE STUFF
  const [inShare, setInShare] = useState(false);
  let shareUrl = `https://www.vacanzeguides.com/user/${state?.user?._id}/guide/${state?.guide?._id}`
  let createText = `sms://?&body=Check out this guide to ${state?.guide?.name}: ${shareUrl}`
  let createEmail = `mailto:?to=&body=Check out this guide to ${state?.guide?.name}: ${shareUrl}&subject=Check out this guide to ${state?.guide?.name}`

  let [currentLogo, setCurrentLogo] = useState();
  const currentNavColor = state?.guide?.colorOne || '#808080'
  const getCurrentLogo = () => {
    // setActiveChangeLogo('true')
    let logo = state?.guide?.logo;
    setCurrentLogo(state?.guide?.logo)
    console.log(logo)
    switch (logo){
      case 'beach':
        setCurrentLogo(beach)
          break;
        case 'cabin':
          setCurrentLogo(cabin)
          break;
        case 'apartment':
          setCurrentLogo(apartment)
          break;
        case 'rv':
          setCurrentLogo(rv)
          break;
        case 'van':
          setCurrentLogo(van)
          break;
        case 'light2':
          setCurrentLogo(lightLogo)
          break;
        case 'dark2':
          setCurrentLogo(darkLogo)
          break;
        case 'dark1':
          setCurrentLogo(dark1)
          break;
        case 'light1':
          setCurrentLogo(light1)
          
          break;
        default:
          setCurrentLogo('https://cdn-ek1u0pq3.resize-files-simplefileupload.com/B2o-ZJ6fF7RAFhwrJ4AK5kAtZN-4mC3oEAjAm0HPvHA/plain/s3://static.files-simplefileupload.com/259s1o43b0g0qc4w4k026tubzose')
          break;
    }
  }

  useEffect(() => {
    if (data) {
      dispatch({
        type: SET_GUIDE,
        guide: data.guide,
      });

      getCurrentLogo();
      shareUrl = `https://www.vacanzeguides.com/user/${state?.user?._id}/guide/${state?.guide?._id}`;
      createText = `sms://&body=Check out this guide to ${state?.guide?.name}: ${shareUrl}`;
      createEmail = `mailto:?to=&body=Check out this guide to ${state?.guide?.name}: ${shareUrl}&subject=Check out this guide to ${state?.guide?.name}`;
    }
  }, [data, loading, dispatch, currentLogo]);


    //toggle edit title & edit title funciton
    const [toggleEditTitle, setToggleEditTitle] = useState('false');
    const [updateGuideTitle] = useMutation(UPDATE_GUIDE_TITLE);
    const [updateGuideTitlePoi] = useMutation(UPDATE_GUIDE_TITLE_POI); 
    const guideName = useRef();

    const editTitle = async () => {
      const newName = guideName.current.value;
      const mutationResponse = await updateGuideTitle({
        variables: {
          guideId: guideId,
          name: newName
        }
      });

      if (mutationResponse) {
        const oldName = mutationResponse.data.updateGuideTitle.poi[0].name;
        const fullyUpdatedGuide = await updateGuideTitlePoi({
          variables: {
            guideId: guideId,
            oldName: oldName,
            newName: newName
          }
        })
        dispatch({
          type: SET_GUIDE,
          guide: fullyUpdatedGuide.data.updateGuideTitlePoi,
        });
        setToggleEditTitle('false')
      }
    };
    
    
    // QR CODE STUFF
    const [url, setUrl] = useState('');
    const [display, setDisplay] = useState('none');
    const [qrGuide, setQrGuide] = useState();
  
    const setUrlAndDisplay = (guide) => {
      setUrl(`https://www.vacanzeguides.com/user/${state?.user?._id}/guide/${guide._id}`);
      setDisplay('active');
      setInShare(false)
      setQrGuide(guide)
    }

    //popup stuff
    const subPop = (e) => {
      console.log(e.target.parentNode)
      e.target.parentNode.classList.add('hidden');
    }

    let [activeHoverIcon, setActiveHoverIcon] = useState(false)
    const handleHoverIcon = () => {
      if(activeHoverIcon == false){
        setActiveHoverIcon(true)
      } else {
        setActiveHoverIcon(false)
      }
    }

  return (
    //hardcoded for now but will call the API to display selected info with a map function of the data
    <div className="font w-full">
      <div className="bg-white my-4  rounded-lg shadow max-w-[1400px] m-auto flex flex-wrap">
        <article className="w-full flex px-5 align-center font-bold text-sm md:text-md">
          <Link to='/dashboard/guides' className="font-bold hover:underline cursor-pointer">Guides</Link>
          <IoIosArrowForward className="h-full"/>
          <p className="hover:underline cursor-pointer">{state?.guide?.name}</p>
          <IoIosArrowForward className="h-full"/>
          {activeEdit === 'map' && (
          <p>Edit Map</p>
        )}
        {activeEdit === 'theme' && (
          <p>Edit Styles</p>
        )}
        {activeEdit === 'info' && (
          <p>Edit Info</p>
        )}
        </article>
{/* NEED TO UPDATED EDIT ICON FUNCTIONALITY */}
        <section className="flex flex-wrap w-full p-2 md:p-5">
        <div className="relative mr-2 " onMouseEnter={() => handleHoverIcon()} onMouseLeave={() => handleHoverIcon()}>
        {activeHoverIcon ? (
          <p className="flex flex-col absolute top-0 left-0 mainColor text-white opacity-1 w-full h-full flex items-center justify-center rounded-xl cursor-pointer leading-5 font-semibold transition-all ease-in">
            <img src={editGray} />
            Edit Icon
          </p>
        ): (
          <p className="flex flex-col absolute top-0 left-0 mainColor text-white opacity-0 w-full h-full flex items-center justify-center rounded-xl cursor-pointer leading-5 font-semibold transition-all ease-in"><img src={editGray} />
            Edit Icon</p>
        )}
        {state?.guide?.logo ? (
          <img alt='house pic' src={currentLogo} className='w-[120px] h-[120px] m-auto rounded-3xl' style={{ backgroundColor: currentNavColor }}/>
        ):(
          <div className="bg-gray-200 w-[100px] h-[100px] flex items-center justify-center rounded-xl leading-5">App <br/>Logo</div>
        )}
        </div>
        <div className="">
        {toggleEditTitle === 'true' ? 
            <div className="w-full flex justify-around">
                <input ref={guideName} type='text' defaultValue={state?.guide?.name} className='font-semibold text-xl shadow-md border-2 border-gray-100 px-3 rounded-md m-1 p-1 md:p-2' />
              <button onClick={() => editTitle()} className='dashboardBtn'>save changes</button>
            </div>
            :
            <article className="flex p-2 pb-0">
            <p className="font-semibold text-3xl mr-3">{state?.guide?.name}</p>
            <button onClick={() => setToggleEditTitle('true')}  className="cursor-pointer h-fit"><img className="w-[25px] h-[25px] relative top-1" src={editGray} /></button>
        </article>}
        
        <article className="flex p-2">
        <EditPhone guideId={guideId} guidePhone={state.guide?.contactPhone}/>
        </article>
        </div>

        <div className="flex flex-wrap ml-auto">
        <Link to={"/user/" + state?.user?._id + "/guide/" + state?.guide?._id} target="blank" id={state?.guide?._id}  className="hoverShadow m-2 p-2 font-medium mainText border-4 border-[#102440] rounded-full h-[100px] w-[100px] flex flex-col justify-center items-center">
            <img className="w-[50px] h-[50px]" src={phone}/>
            <p>View</p>
          </Link>
          <button onClick={() => {setInShare(true)}}  className="hoverShadow m-2 p-2 font-medium mainText border-4 border-[#102440] rounded-full h-[100px] w-[100px] flex flex-col justify-center items-center">
            <img className="w-[50px] h-[50px]" src={share}/>
            <p>Share</p>
          </button>
          <button onClick={() => setActiveEdit('theme')} className="hoverShadow m-2 p-2 font-medium blueGradientBG text-white rounded-full h-[100px] w-[100px] flex flex-col justify-center items-center">
            <img className="w-[50px] h-[50px]" src={brush}/>
            <p>Styles</p>
          </button>
          <button onClick={() => setActiveEdit('map')} className="m-2 p-2 font-medium blueGradientBG text-white rounded-full h-[100px] w-[100px] flex flex-col justify-center items-center">
            <img className="w-[50px] h-[50px]" src={map}/>
            <p>Map</p>
          </button>
          <button onClick={() => setActiveEdit('info')} className="m-2 p-2 font-medium blueGradientBG text-white rounded-full h-[100px] w-[100px] flex flex-col justify-center items-center">
            <img className="w-[50px] h-[50px]" src={info}/>
            <p>Info</p>
          </button>
        </div>
        </section>

        <div className="flex flex-wrap w-full md:w-5/6  m-auto p-1 md:p-2 mb:pb-0">
          <div className="w-full m-auto">

          </div>
        </div>

        {activeEdit === 'map' && (
          <EditAddressComponent guideId={guideId} />
        )}
        {activeEdit === 'theme' && (
          <PhotoTheme guideId={guideId} />
        )}
        {activeEdit === 'info' && (
          <EditSection guideId={guideId} />
        )}
      </div>
      
      <DeleteGuide />
      <QRCodeModal  url={url} display={display} setDisplay={setDisplay} guide={qrGuide}/>
      {!subStatus && (

        <div className="speech-bubble w-80 bg-white border-2 border-gray-500 guideShadow rounded-md absolute top-28 md:top-20 left-8 ">
          <button onClick={subPop} className="text-2xl font-bold absolute text-white mainColor h-6 w-6 rounded" style={{ left: '89%', top:'5%'}}>X</button>
          <p className="font-bold text-xl p-4">You do not have an active subsription for this guide, activate your subsription to see your guide and share it with guests.</p>
        </div>
      )}
      {inShare === true && ( 
        <div className="absolute top-0 w-screen h-screen">
          <div className="absolute w-screen h-screen bg-gray-400 opacity-50" ></div>
          <div className="absolute w-screen h-screen" >
            <div className="m-auto mt-60 md:mt-96 bg-white w-11/12 md:w-1/2 rounded-lg pb-4">
              <header className="flex justify-between bg-gray-100 border-b-2 rounded-t-lg px-5 py-2 font-bold text-2xl mb-4">
                <h3>Share Guide Via...</h3>
                <button onClick={() => setInShare(false)}>X</button>
              </header>
              <div className="w-3/4 m-auto py-3">
              <a href={createEmail} className="mainColor text-white font-bold px-4 pb-1 pt-2 block w-full rounded-lg text-xl">Email</a>
              <div className="my-6">
                <button onClick={() => {setUrlAndDisplay(state?.guide)}} className="mainColor text-white font-bold px-4 pb-1 pt-2 w-full rounded-lg text-xl">QR Code</button>
              </div>
                <a href={createText} className="mainColor text-white font-bold px-4 pb-1 pt-2 block w-full rounded-lg text-xl">Text</a> 
              </div>
            </div>
          </div> 
        </div>
      )} 
    </div>
  )
};

export default EditGuide;