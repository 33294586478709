import React, { useState } from "react";
import { useStoreContext } from "../../../utils/GlobalState";
import { Link } from "react-router-dom";
import GuidesPreview from "./guidesPreview";
import TransferGuide from "./transferGuide";

import { BsQuestionCircle }from 'react-icons/bs';


function Guides() {
  const [state] = useStoreContext();
  
  const numberOfGuides = state?.user?.guides?.length
  const subscriptionQuantity = state?.user?.subscriptionName


return (
  <div className="font-bold font">
    {state?.user?.subscriptionStatus === 'active' && (
      <div className="flex md:flex-wrap justify-between p-5 bg-white w-full  mt-1">
        <h1 className=" text-xl md:text-3xl w-[170px] md:w-3/4 text-left">All Guides ({state?.user?.guides?.length})</h1>
        {numberOfGuides >= subscriptionQuantity ? (
          <div>
          
          <div className="my-auto tooltip">
              <div className="top">
                <p className="text-lg font-bold">You have already made as many guides are you are allowed to with your current subscription, to add more, update your subscription</p>
                <i></i>
              </div>
              <Link to="/dashboard/addGuide" className="mainColor rounded-full p-2 px-3 text-white opacity-60 pointer-events-none">+ Add Guide</Link>
            </div>
          </div>
        ) : (
          <Link to="/dashboard/addGuide" className="mainColor rounded-full p-2 px-3 text-white ">+ Add Guide</Link>
        )}
        
      </div>
      )}
      <div className="">
          <GuidesPreview />
          {state?.user?.email == 'contact@vacanze.com' &&(
            <TransferGuide />
          )}
          
      </div>
  </div>
)
};

export default Guides;