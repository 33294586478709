
import React, { useState, useRef} from "react";
import { useStoreContext } from '../../../../utils/GlobalState';
import { useMutation } from "@apollo/client";
import { SET_GUIDE } from "../../../../utils/actions";
import { UPDATE_GUIDE_ADDRESS, UPDATED_POI } from '../../../../utils/mutations';
import { BsQuestionCircle } from 'react-icons/bs'
import MapContainer from './MapContainer';
import MarkedLocations from "./markedLocations";

const EditAddressComponent = ({guideId}) => {
    const [state, dispatch] = useStoreContext();
    const id = guideId;
    const guideName = state?.guide?.name;

      const [toggleEditAddress, setToggleEditAddress] = useState('false');
      const [updateGuideAddress] = useMutation(UPDATE_GUIDE_ADDRESS);
      const [updatePoi] = useMutation(UPDATED_POI);
      const guideAddress = useRef();

      const editAddress = async () => {
        const newGuideAddress = document.getElementById('newGuideAddress');
        console.log(newGuideAddress.value)
        const mutationResponse = await updateGuideAddress({
          variables: {
            guideId: id,
            address: newGuideAddress.value
          }
        });
        if (mutationResponse) {
          dispatch({
            type: SET_GUIDE,
            guide: mutationResponse.data.updateGuideAddress,
          });
        }
        console.log(newGuideAddress.value)
        let beforeRemoveApt = newGuideAddress.value.split(' ');
        const filteredAddress = beforeRemoveApt.filter(str => !str.startsWith("#"));
        let geocodeAddress = filteredAddress.join('%20').concat('&');
        // console.log(geocodeAddress)
        // 2500 N Fort Ln #209, Layton, UT 84041
        const newLocation = await fetch(`https://maps.googleapis.com/maps/api/geocode/json?address=${geocodeAddress}key=AIzaSyDDkRTAj5v0klTMIXQeDdG8HcT0RbdwrOc`)
        .then(Response => Response.json())
        .then((data) => {
          const newLatLong = data.results[0].geometry.location;
          return newLatLong;
        })
        .catch((err) => {
          // console.log(err)
        });
        console.log(guideName);
        const updatePoiResponse = await updatePoi({
          variables: {
            guideId: guideId,
            name: guideName,
            address: newGuideAddress.value,
            lat: newLocation.lat,
            lng: newLocation.lng
          }
        });
        if (updatePoiResponse) {
          window.location.reload();
          await dispatch({
            type: SET_GUIDE,
            guide: updatePoiResponse.data.updatePoi,
          });
        }
        
        setToggleEditAddress('false')
      }
      
      let pois = state?.guide?.poi;      
  return (
    <div className="w-full md:w-11/12 m-auto p-3 flex flex-wrap justify-around border-b-2 border-gray-300 pb-5 my-5" >
      <section className="flex flex-wrap w-full">
          <div className="w-full md:w-3/4 m-auto">
            <MapContainer pois={pois}/>
          </div>
          <div className="grid content-center p-2 w-full md:w-3/4  m-auto">
          <div className="border-2 shadow rounded-md flex flex-wrap justify-between pt-2">
            <h1 className="text-2xl font-bold w-full md:w-max pt-2 pl-3 mr-5 text-left">Address:</h1>
            {toggleEditAddress === 'true' ? (
              <textarea ref={guideAddress} id='newGuideAddress' type='text' defaultValue={state?.guide?.address} className='w-full md:p-1 md:w-2/3 h-fit border-2 border-gray-300 rounded-md m-auto text-center mb-2'/>
              

            ) : (
              <h2 className="text-xl w-full md:w-max  px-3 md:px-0 mr-auto pt-2 mb-2 md:mb-0">{state?.guide?.address}</h2>
            )}
            
            <div className="flex m-auto md:m-0">
            {toggleEditAddress === 'true' ? (
            <button className='dashboardBtn ml-3 md:ml-0' onClick={() => editAddress()}>save changes</button>
            ):(
              <>
            <button onClick={() => setToggleEditAddress('true')} className='dashboardBtn mb-2 mr-3 ml-3 md:ml-0'>Change Address 📝</button>
            <div className="my-auto tooltip">
                <div className="top">
                  <h3 className="font-bold">Change Address</h3>
                  <p className="text-md">Changing your address will also update the home marker on your map.</p>
                  <i></i>
                </div>
                <BsQuestionCircle className=" text-xl mr-2 text-gray-500 relative bottom-1" />
              </div>
              </>
            )}
              
              
            </div>
          </div>
          </div>
      </section>
      <MarkedLocations />
    </div>
  )
};

export default EditAddressComponent;