import { React } from "react";
import { Link } from 'react-router-dom'
import { MdStarOutline, MdHourglassBottom, MdLockOpen } from 'react-icons/md';
import { BsChatLeftText, BsQuestionLg } from 'react-icons/bs';
import vacanzerotated from '../../assets/3vacanzerotated.png';
import iphone from '../../assets/iphone.png';
import anini from '../../assets/anini.png'
import vacanzePhone1 from '../../assets/vacanzePhone1.png';
import vacanzePhone2 from '../../assets/vacanzePhone4.png';
import Reviews from '../../components/home/details/reviews';
import Pricing from "./pricing";
import previewVideo from "../../assets/previewVideo.mp4";
import Anini from '../../assets/anini.png';
import logo from '../../assets/icons/light512.png'


import { Helmet } from "react-helmet";

const HomeDefault = () => {

return (
  <>  
    <Helmet>
    <title>Vacanze Digital Guidebooks | Vacanze</title>
    <meta property="og:title" content="Vacanze" />
    <meta property="og:url" content="https://www.vacanzeguides.com" />
    <meta property="og:site_name" content="Vacanze Guides" />
    <meta property="og:locale" content="en_US" />
    <link rel="canonical" href="https://www.vacanzeguides.com" />
    <meta name='description' content='The Digital Welcome Book that makes managing your short term rentals easier.'/>
    <meta name="keywords" content="AirBnB, property management, Vacanze, rental, app, Guides, guidebook" />
    <meta property="og:image" content={logo} />
  </Helmet>
  <div className="secondColor homeText homeDefault">
    <h2></h2>
    <div className="curved curveSvg  md:block">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 319"><path fill="#e7dfc6" fillOpacity="1" d="M0,96L48,85.3C96,75,192,53,288,42.7C384,32,480,32,576,48C672,64,768,96,864,112C960,128,1056,128,1152,122.7C1248,117,1344,107,1392,101.3L1440,96L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z"></path></svg>
    </div>
    <section className="mainHeader w-11/12 md:w-4/5 pb-40 md:pb-24 py-3 mx-auto flex justify-center flex-wrap md:flex-nowrap">
      <div>
        <h1 className="text-3xl relative md:bottom-12 sm:text-5xl md:text-5xl lg:text-5xl font-bold relative mt-5 md:mt-32 w-full md:w-[750px] text-left">The Digital Welcome Book that makes managing your rentals easier.</h1>
        <h2 className="relative md:bottom-6 z-20 text-xl md:text-2xl w-full  md:w-[650px] text-left">We help you make digital welcome books for your short term rental, campground, RV rental, or any other kind of business or event! </h2>
        <div className="w-full flex mt-3">
        <Link to='/login' className="relative md:bottom-4 bg-white homeText px-3 py-2 rounded-md font-bold m-auto mb-6 md:m-0 md:mr-auto">Start your journey!</Link>
        </div>
      </div>
      <div className=" rounded-[20px] ml-20">
        {/* <iframe className="homeIframe" width="350" height="650" src={previewVideo} frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen ></iframe> */}
        {/* <iframe className="homeIframe" width="350" height="650" src="https://www.loom.com/embed/fd4b3193adb54651bc9d51df4b37a7aa?autoplay=1&loop=1" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen ></iframe> */}
        {/* <video className="w-[350%] h-[650px]" src={previewVideo} type="video/mp4" width="650" height="650"> */}
        {/* </video> */}
        <img className='w-[400px] m-auto' alt="Example Guide" src={Anini}/>
      </div>
      
    </section>
    
    <article className="secondColor curveSvg hidden md:block ">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 319"><path fill="white" fillOpacity="1" d="M0,96L48,85.3C96,75,192,53,288,42.7C384,32,480,32,576,48C672,64,768,96,864,112C960,128,1056,128,1152,122.7C1248,117,1344,107,1392,101.3L1440,96L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z"></path></svg>
    </article>
  </div>

  <div id="homeTeaser" className="homeText relative md:top-20 bg-white px-4">
    <h3 className="text-3xl font-bold pt-5 mb-6">What Does <span className="logoFont">VACANZE</span> do For Me?</h3>
    <p className="w-11/12 md:w-1/2 m-auto">Digital property guide books allow you to answer your guests' questions easily and share all the information about your property you want directly to your guests' mobile phone.</p>
    <div className="flex flex-wrap w-5/6 m-auto justify-around mt-8"> 
      <div className="w-full lg:w-1/3 text-left">
        <MdHourglassBottom className="text-9xl m-auto homeText" />
        <h4 className="w-11/12 md:w-4/5 m-auto text-xl font-bold">Seamless Property Management</h4>
        <p className="w-11/12 md:w-4/5 w-11/12 md:w-4/5 m-auto mb-5 md:mb-0 relative z-90">Simplify and automate your property management processes. With our app, you can effortlessly share vital details about your rental property, such as access codes, check-in/check-out times, and specific instructions. Keep your guests informed and ensure a smooth and hassle-free experience for everyone involved.</p>
      </div>
      <div className="w-full lg:w-1/3 relative z-90 text-left">
        <MdLockOpen className="text-9xl m-auto homeText" />
        <h4 className="w-11/12 md:w-4/5 m-auto text-xl font-bold">Personalize Your Guest Experience</h4>
        <p className="w-11/12 md:w-4/5 m-auto mb-5 md:mb-0 relative z-90 text-left">Delight your guests with a tailored and unforgettable stay. Our app allows you to customize every aspect of their experience, providing essential information about local attractions, restaurants, activities, and amenities. From hidden gems to popular hotspots, your guests will have all the information they need at their fingertips.</p>
      </div>
      <div className="w-full lg:w-1/3 relative z-90 text-left">
        <MdStarOutline className="text-9xl m-auto homeText relative z-90" />
        <h4 className="w-11/12 md:w-4/5 m-auto text-xl font-bold">Stand Out from the Crowd</h4>
        <p className="w-11/12 md:w-4/5 m-auto relative z-90 text-left">Gain a competitive edge in the vacation rental market. With our app, you can showcase the unique features and highlights of your property, giving potential guests an enticing preview of their stay. Leave a lasting impression and attract more bookings with our comprehensive and visually appealing platform.</p>
      </div>
    </div>

  </div>

  <div className="curveSvg  relative z-10 md:z-0  md:block rotate-180">
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 319"><path fill="#ffffff" fillOpacity="1" d="M0,96L48,85.3C96,75,192,53,288,42.7C384,32,480,32,576,48C672,64,768,96,864,112C960,128,1056,128,1152,122.7C1248,117,1344,107,1392,101.3L1440,96L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z"></path></svg>
  </div>
    <div className='flex flex-wrap mainColor w-full justify-around mb-8 md:mb-0 py-10 relative'>
      <div className="w-11/12 md:w-2/3 mt-8">
        <BsQuestionLg className="text-9xl m-auto secondText mb-6"/>
        <h3 className="w-full text-5xl text-white pb-6 md:pb-2">What is the ROI on <span className="logoFont">VACANZE</span>?</h3>
        <p className="text-2xl text-white w-11/12 md:w-3/4 m-auto mb-8 md:mb-0">Better reviews lead to more bookings, and fewer questions mean more time to run your business. It's a win-win.</p>
      </div>
      <svg className="mb-[-15rem] w-full" viewBox="0 0 1440 320"><path fill="#ffffff" fillOpacity="1" d="M0,96L120,90.7C240,85,480,75,720,69.3C960,64,1200,64,1320,64L1440,64L1440,320L1320,320C1200,320,960,320,720,320C480,320,240,320,120,320L0,320Z"></path></svg>

      <Reviews className='relative'
      reviewArray={[
        {
          img: 'https://cdn-ek1u0pq3.resize-files-simplefileupload.com/CUl-TZRgQB_3MPh9elNg4A47DB0URUoUHDjfEoJyHRs/plain/s3://static.files-simplefileupload.com/n9iwm3fz7xn55140b665xxb0e6yi',
          name: 'Doris Parke',
          guide: 'Fish Creek Lodge',
          link: 'https://vacanze.herokuapp.com/user/63d9d428f366de6c77a3f86c/guide/63e5195afc37c32a6db18017',
          testimonial: 'We love Vacanze! It has made running our rental properties so much easier!'
        },
        {
          img: 'https://cdn-ek1u0pq3.resize-files-simplefileupload.com/eaYQIso8ZmBXg4J2RFo8L2XZCaljNkbrqVYgA4yvo3E/plain/s3://static.files-simplefileupload.com/g6l1nuvgqx0zeks6n1vcdjq1niwr',
          name: 'Parker Saxton',
          guide: 'Anini Beach Bungalow',
          link: 'https://vacanze.herokuapp.com/user/63d9d428f366de6c77a3f86c/guide/6429d1e99590aabaa72cadae',
          testimonial: "I've cut the time I spent communticating with guests in half, while still giving them all the information the could need or want."
        },
        {
          img: 'https://www.switchbacktravel.com/sites/default/files/images/articles/Climbing%20down%20Sprinter%20van%20rear%20ladder%20%28on%20beach%29.jpg',
          name: 'Tanner Shepard',
          guide: 'Desert Getaway Van',
          link: 'https://vacanze.herokuapp.com/user/63d1f9a910d91e3807940d29/guide/6436e8585a5a2091cbf2e33a',
          testimonial: 'My rentals have gotten much more return business since I started using Vacanze!'
        },
      ]}
      />

      <svg className="curveSvg bg-white w-full" viewBox="0 0 1440 320"><path fill="#102440" fillOpacity="1" d="M0,96L120,90.7C240,85,480,75,720,69.3C960,64,1200,64,1320,64L1440,64L1440,320L1320,320C1200,320,960,320,720,320C480,320,240,320,120,320L0,320Z"></path></svg>
      <div className='flex flex-wrap w-full mx-3 mb-5 md:mb-3 md:pt-12'>
        <div className="w-full flex flex-wrap md:flex-nowrap md:w-3/5 m-auto text-left mb-6">
        <div className='grid content-center w-full md:w-1/2 mt-8 md:mt-0 mr-12 text-left'>
          <p className='font-bold text-white text-3xl pb-5 ml-6 md:ml-0 w-11/12 md:w-2/3'>The App That Gives Your Guests a Digital Guide of Your Property.</p>
          <p className="text-white text-left ml-6  md:ml-0 mr-auto">We store all your content in a single online location. Then we use a web app to package it up into an easy-to-use, beautiful welcome book format.  Once it's packaged up, you can share your welcome book with your guests by sending them a quick link (and they'll never need to sign up or download anything!) </p>
        </div>
        <a href="https://vacanze.herokuapp.com/user/63d9d428f366de6c77a3f86c/guide/6429d1e99590aabaa72cadae" target="_blank">
        <img className='w-72 rounded-3xl mt-6 md:mt-0 m-auto md:ml-16' alt='ex' src={anini} />
        </a>
        </div>
        <div className="w-full">
          <section className="w-full flex flex-wrap md:flex-nowrap md:w-3/5 m-auto text-left pt-6 md:pt-16">
            <article className="w-11/12 md:w-1/3 text-white mr-6 mb-4">
              <h4 className=" w-fit pl-3 font-bold text-2xl border-l-2 border-[#e7dfc6] mb-2">Share Important info</h4>
              <p className="text-gray-200 ml-6 md:ml-0">Share any information you want with your guests in an easy to read and easy to follow along way.</p>
            </article>
            <article className="w-11/12 md:w-1/3 text-white mr-6 mb-4">
              <h4 className=" w-fit pl-3 font-bold text-2xl border-l-2 border-[#e7dfc6] mb-2">Ease of use</h4>
              <p className="text-gray-200 ml-6 md:ml-0">Your welcome book will be as easy as the click of a button for your guests to use! Show your guests everything they need to know about your place or business.</p>
            </article>
            <article className="w-11/12 md:w-1/3 text-white mr-6 mb-4">
              <h4 className=" w-fit pl-3 font-bold text-2xl border-l-2 border-[#e7dfc6] mb-2">Get guests excited</h4>
              <p className="text-gray-200 ml-6 md:ml-0">Your welcome book will be as easy as the click of a button for your guests to use and see everything they need about your place or business.</p>
            </article>
          </section>
          {/* <div className="w-1/2 m-auto flex justify-between">
            <button className=" mr-auto bg-white homeText pl-2 pr-16 text-left py-1 pt-2 font-bold text-xl rounded-md">Call to Action</button>
            <p>.</p>
          </div> */}
          
        </div>
        <svg className="mb-[-19rem] w-screen" viewBox="0 0 1440 320"><path fill="#ffffff" fillOpacity="1" d="M0,96L120,90.7C240,85,480,75,720,69.3C960,64,1200,64,1320,64L1440,64L1440,320L1320,320C1200,320,960,320,720,320C480,320,240,320,120,320L0,320Z"></path></svg>
      </div>
      {/* <div className='flex flex-wrap w-full m-3 homeText bg-white pb-16 w-full'>
        <div className="flex flex-wrap w-1/2 m-auto">
        <div className='grid content-center w-full md:w-1/2 flex md:hidden my-auto'>
          <p className='font-bold text-2xl pb-5'>Show Your Property Along With any Other Locations you Want Your Guests to See With the Built in Map.</p>
        </div>
        <img className='w-48 rounded-3xl ml-auto ml-6' alt='ex' src={vacanzePhone2} />
        <div className='grid content-center w-full md:w-1/2 hidden md:flex flex-wrap my-auto text-left ml-20'>
          <p className='font-bold text-3xl pb-5 mr-auto'>Easy to use built in map</p>
          <p className="text-left">Show Your Property Along With any Other Locations you Want Your Guests to See With the Built in Map.</p>
        </div>
        <div className="w-full pt-16">
          <section className="w-full flex m-auto text-left text-black">
            <article className="w-1/3 mr-6">
              <h3 className=" w-fit pl-3 font-bold text-2xl border-l-2 border-cyan-300 mb-2">Mark Your Favorite Spots</h3>
              <p className="text-gray-700">Share your Favorite restaurants, beaches, museums, or any other type of place with your guests.</p>
            </article>
            <article className="w-1/3 mr-6">
              <h3 className=" w-fit pl-3 font-bold text-2xl border-l-2 border-cyan-300 mb-2">Share "Locals Only" Info</h3>
              <p className="text-gray-700">Your welcome book will be as easy as the click of a button for your guests to use and see everything they need about your place or business.</p>
            </article>
            <article className="w-1/3 mr-6">
              <h3 className=" w-fit pl-3 font-bold text-2xl border-l-2 border-cyan-300 mb-2">Give Directions</h3>
              <p className="text-gray-700">Simply click on the address of one your marked locations and it will open that location on whatever navigation app you use to get directions.</p>
            </article>
          </section>
        </div>
      </div> */}
      {/* </div> */}
    </div>
    {/* <div className="mainColor text-white"> */}
      {/* <h2 className="text-4xl font-bold pt-6">Features That We Provide</h2>
      <div className="h-2 w-60 secondColor m-auto my-2"></div>
      <p className="w-full md:w-3/5 m-auto text-xl mt-6">(changeThis) When you make something digital you open up a world of possibilities. Take a look at some of the extra features you’ll have access to with your VACANZE subscription…</p> */}
      {/* <section className="w-full md:w-3/4 m-auto flex flex-wrap justify-around pb-16">
        <article className="w-full md:w-1/4 mx-2">
          <BsChatLeftText className="secondText text-6xl m-auto" />
          <h3 className="text-2xl font-bold my-4">Easy to access practical info</h3>
          <p className="text-xl">Provide information about directions, access, checkout procedures and more. All ready for your guests to access before and during their stay.</p>
        </article>
      </section>
      </div> */}
      <Pricing />
    </>
)}

export default HomeDefault;