import React, { useRef, useState, useEffect } from "react";
import { UPDATE_COLOR_ONE, UPDATE_COLOR_TWO, UPDATE_LOGO, UPDATE_PHOTO } from "../../../utils/mutations";
import { useMutation } from "@apollo/client";
import { useStoreContext } from '../../../utils/GlobalState';
import emailIcon from '../../../assets/email.png';
import phoneOutline from '../../../assets/phoneOutline.png';
import { BsHouse, BsBook, BsMap, BsTelephone, BsShare, BsWifi, BsKey,BsHandThumbsUp, BsQuestionCircle } from 'react-icons/bs';
import { IoMdPaperPlane } from 'react-icons/io'
import { ChromePicker  } from 'react-color'
import rgbHex from "rgb-hex";

import apartment from '../../../assets/icons/Appartement.png';
import beach from '../../../assets/icons/Beach-House.png';
import cabin from '../../../assets/icons/Cabin.png';
import rv from '../../../assets/icons/RV.png';
import van from '../../../assets/icons/Sprinter-Van.png';
import darkLogo from '../../../assets/icons/dark512.png';
import lightLogo from '../../../assets/icons/light512.png';
import dark1 from '../../../assets/icons/dark.png';
import light1 from '../../../assets/icons/light.png';

import SimpleFileUpload from "react-simple-file-upload";


const PhotoTheme = () => {
  const [state, dispatch] = useStoreContext();

  const [updateGuideColorOne] = useMutation(UPDATE_COLOR_ONE);
  const [updateGuideColorTwo] = useMutation(UPDATE_COLOR_TWO);

  const [activeColorOne, setActiveColorOne] = useState(false);
  const [activeColorTwo, setActiveColorTwo] = useState(false);

  const currentNavColor = state?.guide?.colorOne || '#808080'
  const currentIconColor = state?.guide?.colorTwo || '#808080'

  const [navColor, setNavColor] = useState(state?.guide?.colorOne || "#808080");
  const [iconColor, seticonColor] = useState(state?.guide?.colorTwo || "#808080");

  const confirmColorOneChange = async() => {
    try {
      const updatedGuideColorOne = await updateGuideColorOne({
        variables: {
          guideId: state?.guide?._id,
          colorOne: navColor
        }
      })
      setActiveColorOne(false);
    } catch (e) {
      console.log(e);
    }
  }
  const confirmColorTwoChange = async() => {
    try {
      const updatedGuideColorTwo = await updateGuideColorTwo({
        variables: {
          guideId: state?.guide?._id,
          colorTwo: iconColor
        }
      })
      setActiveColorTwo(false)
    } catch (e) {
      console.log(e);
    }
  }

  // Change Primary Image State

  const [primaryImageState, setPrimaryImageState] = useState();

  function handlePrimaryImageFile(url){
    console.log('The URL of the file is ' + url)
    setPrimaryImageState(url);
  }

  const [activeChangePhoto, setActiveChangePhoto] = useState('false');
  const [updateGuidePhoto] = useMutation(UPDATE_PHOTO);
  const confirmPhotoChange = async() => {
    setActiveChangePhoto('false');
    try {
      const updatedGuidePhoto = await updateGuidePhoto({
        variables: {
          guideId: state?.guide?._id,
          photo: primaryImageState
        }
      })
    } catch (e) {
      console.log(e);
    }
  }

  // Change Logo Logic

  const [logoImageState, setlogoImageState] = useState();

  function handleLogoImageFile(url){
    console.log('The URL of the file is ' + url)
    setlogoImageState(url);
  }

  const icon1 = useRef()
  const icon2 = useRef()
  const icon3 = useRef()
  const icon4 = useRef()
  const icon5 = useRef()
  const icon6 = useRef()
  const icon7 = useRef()
  const icon8 = useRef()
  const icon9 = useRef()
  function handleChooseIcon(e){
    setlogoImageState(e.currentTarget.id)
    console.log(e.currentTarget.id)
    //remove border from other icons
    if(icon1.current.classList.contains('border-8')){
      icon1.current.classList.remove('border-8')
    }
    if(icon2.current.classList.contains('border-8')){
      icon2.current.classList.remove('border-8')
    }
    if(icon3.current.classList.contains('border-8')){
      icon3.current.classList.remove('border-8')
    }
    if(icon4.current.classList.contains('border-8')){
      icon4.current.classList.remove('border-8')
    }
    if(icon5.current.classList.contains('border-8')){
      icon5.current.classList.remove('border-8')
    }
    if(icon6.current.classList.contains('border-8')){
      icon6.current.classList.remove('border-8')
    }
    if(icon7.current.classList.contains('border-8')){
      icon7.current.classList.remove('border-8')
    }
    if(icon8.current.classList.contains('border-8')){
      icon8.current.classList.remove('border-8')
    }
    if(icon9.current.classList.contains('border-8')){
      icon9.current.classList.remove('border-8')
    }

    //add border to chosen icon
    e.currentTarget.children[0].classList.add('border-8')
    e.currentTarget.children[0].classList.add('border-cyan-400')
  }

  const [activeChangeLogo, setActiveChangeLogo] = useState('false');
  const [updateGuideLogo] = useMutation(UPDATE_LOGO);
  const confirmLogoChange = async() => {
    setActiveChangeLogo('false');
    try {
      const updatedGuideLogo = await updateGuideLogo({
        variables: {
          guideId: state?.guide?._id,
          logo: logoImageState
        }
      })
    } catch (e) {
      console.log(e);
    }
  }

  //get current logo
  const [currentLogo, setCurrentLogo] = useState('')
  const getCurrentLogo = () => {
    setActiveChangeLogo('true')
    let logo = state?.guide?.logo
    setCurrentLogo(state?.guide?.logo)
    console.log(logo)
    switch (logo){
      case 'beach':
        setCurrentLogo('https://cdn-ek1u0pq3.resize-files-simplefileupload.com/-RA2GcPwfjEvo6ioj07QB-A2EX2nuhz85iKdbdvBwsw/plain/s3://static.files-simplefileupload.com/gll15p58xw7h3pjhsm3p5znvtg1p')
          break;
        case 'cabin':
          console.log('working')
          setCurrentLogo('https://cdn-ek1u0pq3.resize-files-simplefileupload.com/lAYtPtq6Y9umjznSjr9stjJKJHOGc7J0umN67XAiHK4/plain/s3://static.files-simplefileupload.com/s3ulwgcal98r5afume0h8ni9lrm1')
          break;
        case 'apartment':
          setCurrentLogo('https://cdn-ek1u0pq3.resize-files-simplefileupload.com/2HZdQu1lIJaNNACcVpPSuYHEdmSe4fsr0jo979hBr9A/plain/s3://static.files-simplefileupload.com/tqs5xbnziwms9qgno7au61r5pugb')
          break;
        case 'rv':
          setCurrentLogo('https://cdn-ek1u0pq3.resize-files-simplefileupload.com/1BBMQnDevJUKiFfSucHRXMEBk0gL2qQhBufwVxLIQ_8/plain/s3://static.files-simplefileupload.com/8m25tzi1tcq8ekq27jcom097sids')
          break;
        case 'van':
          setCurrentLogo('https://cdn-ek1u0pq3.resize-files-simplefileupload.com/5Gc6V918UcREFKlodcuu2SD7FGsuIokGN4XW3hm0so8/plain/s3://static.files-simplefileupload.com/84q8yl85lqk89ivniljb50t4qqo8')
          break;
        case 'light2':
          setCurrentLogo('https://cdn-ek1u0pq3.resize-files-simplefileupload.com/B2o-ZJ6fF7RAFhwrJ4AK5kAtZN-4mC3oEAjAm0HPvHA/plain/s3://static.files-simplefileupload.com/259s1o43b0g0qc4w4k026tubzose')
          break;
        case 'dark2':
          setCurrentLogo('https://cdn-ek1u0pq3.resize-files-simplefileupload.com/Ohhz7MdR7zlM-qjeK9psSpBA_Q9jVHG-LlkLZH8A074/plain/s3://static.files-simplefileupload.com/0xewkjee7529sa9vdjfbi2nyfflr')
          break;
        case 'dark1':
          setCurrentLogo('https://cdn-ek1u0pq3.resize-files-simplefileupload.com/16v_LNEtlpL3SoRJxv38jsCGJ10qv8lhB5AcmdEAEuk/plain/s3://static.files-simplefileupload.com/xuh59ywoo2y83n9auicm3t6tsibx')
          break;
        case 'light1':
          setCurrentLogo('https://cdn-ek1u0pq3.resize-files-simplefileupload.com/lRy_FWNxDVKa4PXa5KYBiqMlQXEGx1cG36U2lvRtjCo/plain/s3://static.files-simplefileupload.com/az4wogdnc69lojoazr89fjvbjys8')
          
          break;
        default:
          setCurrentLogo('https://cdn-ek1u0pq3.resize-files-simplefileupload.com/B2o-ZJ6fF7RAFhwrJ4AK5kAtZN-4mC3oEAjAm0HPvHA/plain/s3://static.files-simplefileupload.com/259s1o43b0g0qc4w4k026tubzose')
          break;
    }
  }
  console.log(state?.guide)
  return (
    <div className="mt-5 pb-5 flex flex-wrap justify-around mb-[20rem] md:mb-0 md:w-full w-11/12 m-auto guide">
      <div className="flex flex-wrap w-full ml-auto mb-6">
        <div className="w-full md:w-4/5 md:m-auto max-w-[1100px] pt-2">
            <div className="inline flex border-2 h-16 px-2 md:px-4 rounded-xl shadow">
              <p className="pt-2 md:pt-4 pr-2 md:text-3xl">Nav Bar Color: </p>
              <p className="w-12 mt-1 mr-2 h-12 rounded-xl" style={{ backgroundColor: currentNavColor }}></p>
              <button onClick={() => setActiveColorOne(true)} className="dashboardBtn ml-auto">Change Nav Color</button>
              <div className="my-auto tooltip">
              <div className="top">
                <h3 className="font-bold">Navigation Color</h3>
                <p className="text-md">This will change the color of the navigation bar at the bottom of your guide.</p>
                <i></i>
              </div>
              <BsQuestionCircle className=" text-sm mr-2 text-gray-500 relative ml-3" style={{ top: '2px'}}/>
            </div>
            </div>
            <div className="inline flex border-2 h-16 px-2 md:px-4 rounded-xl mt-3 shadow">
              <p className="pt-2 md:pt-4 md:pr-2 md:text-3xl">Top Bar & Icon Color: </p>
              <p className="w-12 mt-1 mr-2 md:mr-0 h-12 rounded-xl" style={{ backgroundColor: currentIconColor }}></p>
              <button onClick={() => setActiveColorTwo(true)}  className="dashboardBtn ml-auto">Change Top & Icon Color</button>
              <div className="my-auto tooltip">
              <div className="top">
                <h3 className="font-bold">Top & Icon Color</h3>
                <p className="text-md">This will change the color that goes across the top of your guide, along with the color of the icons on each section of your guide.</p>
                <i></i>
              </div>
              <BsQuestionCircle className=" text-sm mr-2 text-gray-500 relative ml-3" style={{ top: '2px'}}/>
            </div>
            </div>
          <div className="w-full shadow rounded-xl">
            <div className="inline flex border-2 h-16 px-2 md:px-4 rounded-xl mt-3">
              <p className="pt-4 pr-2 md:text-3xl">Photos: </p>
              <button onClick={() => setActiveChangePhoto('true')} className='ml-auto  dashboardBtn'>
                  Change Background Photo
              </button>
              <div className="my-auto tooltip">
                <div className="top">
                  <h3 className="font-bold">Background Photo</h3>
                  <p className="text-md">This will change the photo that is used for the background of your guide'
                  s home page, most users select a picture of their property or business.</p>
                  <i></i>
                </div>
                <BsQuestionCircle className=" text-sm mr-2 text-gray-500 relative ml-3" style={{ top: '2px'}}/>
              </div>
              <button onClick={() => getCurrentLogo()} className='ml-2  dashboardBtn'>
                  Change Icon
              </button>
              <div className="my-auto tooltip">
              <div className="top">
                <h3 className="font-bold">Change Icon</h3>
                <p className="text-md">This will change the icon that will appear on smartphone homescreens if you save the guide to your smartphone.</p>
                <i></i>
              </div>
              <BsQuestionCircle className=" text-sm mr-2 text-gray-500 relative ml-3" style={{ top: '2px'}}/>
            </div>
            </div>
          </div>
        </div>
      </div>
      
      <div className="photoThemePrev pb-40 md:pb-0 mb-40 w-full w-3/5 m-auto mt-8 md:mt-0 flex flex-wrap jusity-around">
        
      <article className="w-64 relative md:left-30">
      
      <div id="GUIDE_PREVIEW" className="w-64 previewRounded previewHeight">
        <div className="guideHome previewHeight w-64 previewRounded  bg-green z-0">
          <div className="guideHomebg h-full w-full">
            <img className="opacity-60 h-full w-60 m-auto object-cover previewRounded" src={state?.guide?.photo} />
          </div>
          <div className="flex flex-col justify-around pb-28 relative top-28">
            <div className="w-5/6 mx-auto">              
              <h1 className="font-bold text-lg text-white font-bold text-shadow">Welcome to </h1>
              <strong className="text-xl text-white font-bold text-shadow">{state?.guide?.name}</strong>

              <div className="flex justify-around mt-3"> 
                <div className="flex border-b-2">
                  <a href={'tel:' + state?.guide?.contactPhone} className="text-4xl pr-2 mt-4">✆</a>
                  <a href={'mailto:' + state?.user?.email}>
                  <img className="w-8 h-8 mt-5 ml-2 text-shadow" src={emailIcon} alt="phone" />
                  </a>
                </div>
              </div>
              <div className="flex justify-around">
                <p className="w-2/3 text-sm font-bold text-white font-bold text-shadow">{state?.guide?.address}</p>
              </div>
            </div>
          </div>
          <section id="GUIDE_NAV" className='navPreview m-auto absolute top-[400px]'>
          <div className="flex text-white relative left-2 p-3 px-0 w-60 previewNav border-t-2 rounded-b-lg" style={{ backgroundColor: currentNavColor }}> 
          {/* <div className="flex text-white p-3 navDefault" >  */}
            <ul className="w-full flex justify-around mt-1">
                <li className="list active mx-6">
                  <button>
                    <BsHouse className="text-xl text-center w-full" />
                    <span className="text text-sm">Home</span>
                  </button>
                </li>
                <div className='navBreak w-0.5'></div>
                <li className="list mx-6">
                  <button>
                    <BsBook className="text-2xl text-center w-full mt-1" />
                    {/* <span className="text text-sm">Guide</span> */}
                  </button>
                </li>
                <div className='navBreak w-0.5'></div>
                <li className="list mx-6">
                  <button>
                    <BsMap className="text-2xl text-center w-full mt-1" />
                    {/* <span className="text text-sm">Map</span> */}
                  </button>
                </li>
            </ul>
          </div>
        </section>
        </div>
      </div>
      <img className="w-full previewHeight absolute top-0" src={phoneOutline} />
      </article>
      <article className="m-auto w-64 relative md:mt-0 mt-[150px] op-[190%] md:top-auto">

      <section id="GUIDE_INFO" className="w-64 m-auto mt-6 previewRounded previewHeight relative ">
        <div className='w-full text-white w-full px-4 flex py-2 previewRoundedTop' style={{ backgroundColor: currentIconColor }}>
            <div className='w-10 h-8 m-1 rounded-full border-2 border-black'>
              <p className='w-full h-full rounded-full bg-black'></p>
            </div>
            <div className="text-3xl my-auto flex w-1/5 justify-between pl-1">
              <IoMdPaperPlane className="mr-2" />
              <BsTelephone />
            </div>
            <div className="ml-auto mt-2">
              <BsShare />
            </div>
        </div>
        <div className="grid grid-cols-2 m-1 mb-0 my-0 rounded-lg bg-gray-50">
          <div className="bg-white border-2 border-gray-100 w-full h-36 grid place-items-center rounded-md">
            <div className='w-full text-xl'>
              <BsHandThumbsUp className="w-full text-4xl" style={{ color: currentIconColor }}/>
              <h3 className="mt-2 capitalize font-bold">Welcome</h3>
            </div>
          </div>
          <div className="bg-white border-2 border-gray-100 w-full h-36 grid place-items-center rounded-md">
            <div className='w-full text-xl'>
              <BsWifi className="w-full text-4xl" style={{ color: currentIconColor }}/>
              <h3 className="mt-2 capitalize font-bold">Wifi</h3>
            </div>
          </div>
          <div className="bg-white border-2 border-gray-100 w-full h-36 grid place-items-center rounded-md">
            <div className='w-full text-xl'>
              <BsKey className="w-full text-4xl" style={{ color: currentIconColor }}/>
              <h3 className="mt-2 capitalize font-bold">Check-In</h3>
            </div>
          </div>
          <div className="bg-white border-2 border-gray-100 w-full h-36 grid place-items-center rounded-md mb-8">
            <div className='w-full text-xl'>
              <BsBook className="w-full text-4xl" style={{ color: currentIconColor }}/>
              <h3 className="mt-2 capitalize font-bold">Check-Out</h3>
            </div>
          </div>
        </div>
        <section id="GUIDE_NAV" className='rounded-lg m-auto mt-2 relative'>
          <div className="absolute w-full testBg opacity-30"></div>
          <div className="relative z-0 flex text-white p-3 previewNav border-t-2 rounded-b-lg" style={{ backgroundColor: currentNavColor }}> 
          {/* <div className="flex text-white p-3 navDefault" >  */}
            <ul className="w-full flex justify-around">
                <li className="list active">
                  <button>
                    <BsHouse className="text-2xl text-center w-full  mt-1" />
                    {/* <span className="text text-sm">Home</span> */}
                  </button>
                </li>
                <div className='navBreak w-0.5'></div>
                <li className="list">
                  <button>
                    <BsBook className="text-xl text-center w-full" />
                    <span className="text text-sm">Guide</span>
                  </button>
                </li>
                <div className='navBreak w-0.5'></div>
                <li className="list">
                  <button>
                    <BsMap className="text-2xl text-center w-full  mt-1" />
                    {/* <span className="text text-sm">Map</span> */}
                  </button>
                </li>
            </ul>
          </div>
        </section>
      </section>
      <img className="w-full previewHeight absolute top-0 z-0" src={phoneOutline} />
      </article>
      </div>

      {activeChangePhoto === 'true' && (
        <div className="fixed bottom-0 w-screen h-screen">
        <div className="w-screen h-screen fixed bg-gray-600 opacity-40"></div>
        <div className="w-screen fixed top-40">
              <div className="m-auto bg-white border-2 w-11/12 md:w-1/4 rounded-md pt-2 z-90">
                <p className=" font-bold my-2">Current Background Image:</p>
                <img alt='house pic' src={state?.guide?.photo} className='w-1/2 m-auto mb-3' />
                <p className=" font-bold">Add New Image Below</p>
                <div className="w-1/2 md:w-1/3 mb-5 m-auto">
                  <SimpleFileUpload
                    apiKey="b0c9e9b3278499b0b02bced650795a13"
                    preview="true"
                    onSuccess={handlePrimaryImageFile}
                  />
                </div>
                
                <button className="border-2 border-[#1e4259] hover:bg-gray-100 homeText font-bold p-2 rounded-md mb-5 hover:shadow-xl" onClick={() => confirmPhotoChange()}>Confirm Change</button>
                <button onClick={() => {setActiveChangePhoto('false')}} className='bg-red-500 hover:bg-red-600 hover:shadow-xl text-white font-bold p-2 rounded-md ml-5  mb-12'>Cancel</button>
              </div>
              </div>
        </div>
      )}

      {activeChangeLogo === 'true' && (
        <div className="fixed bottom-0 w-screen h-screen">
        <div className="w-screen h-screen fixed bg-gray-600 opacity-40"></div>
        <div className=" w-screen">
        <div className="m-auto relative z-10 mt-20 bg-gray-50 border-2 w-11/12 md:w-[900px] rounded-md pt-2 ">
          <header className="flex justify-between font-bold text-2xl px-5 pt-2 border-b-2 bg-gray-100">
            <h3>Edit App Icon</h3>
            <button onClick={() => {setActiveChangeLogo('false')}} className='font-bold p-2 hover:shadow-xl'>X</button>
          </header>
        <div className="overflow-y-scroll overflow-x-hidden max-h-[400px] md:max-h-[90vw]">
        <p className="font-bold my-2">Current Icon:</p>
          {state?.guide?.logo ? (<img alt='house pic' src={currentLogo} className='w-[120px] md:w-[200px] m-auto' />):(<div>none</div>)}
          <p className="font-bold mt-3">Select New Icon Below</p>
          <div className="w-full md:w-[900px] mb-5 m-auto">
          <section className="flex flex-wrap w-11/12 m-auto justify-around md:max-w-[700px]">
            <button  id="apartment" onClick={handleChooseIcon} className="w-[120px] h-[120px]  mt-5 cursor-pointer rounded-[40px]">
              {/* fixxx this */}
              <img ref={icon1} src={apartment} style={{background: navColor}} className="bg-gray-500 w-[140px] underShadow hover:w-[150px] rounded-[40px]"/>
            </button>
            <button id="beach" onClick={handleChooseIcon} className="w-[120px] h-[120px]  mt-5 cursor-pointer rounded-[40px]">
              <img ref={icon2} src={beach} style={{background: navColor}} className="w-[140px] underShadow hover:w-[150px] rounded-[40px]"/>
            </button>
            <button id="cabin" onClick={handleChooseIcon} style={{background: navColor}} className="w-[120px] h-[120px] mt-5 cursor-pointer rounded-[40px]">
              <img ref={icon3} src={cabin} className="w-[140px] underShadow hover:w-[150px] rounded-[40px]"/>
            </button>
            <button id="rv" onClick={handleChooseIcon} style={{background: navColor}} className="w-[120px] h-[120px]  mt-5 cursor-pointer rounded-[40px]">
              <img ref={icon4} src={rv} className="w-[140px] underShadow hover:w-[150px] rounded-[40px]"/>
            </button>
            <button id="van" onClick={handleChooseIcon} style={{background: navColor}} className="w-[120px] h-[120px]  mt-5 cursor-pointer rounded-[40px]">
              <img ref={icon5} src={van} className="w-[140px] underShadow hover:w-[150px] rounded-[40px]"/>
            </button>
            <button id="light2" onClick={handleChooseIcon} style={{background: '#fff'}} className="w-[120px] h-[120px] mt-5 cursor-pointer rounded-[40px]">
              <img ref={icon6} src={lightLogo} className="w-[140px] underShadow hover:w-[150px] rounded-[40px]"/>
            </button>
            <button id="dark2" onClick={handleChooseIcon} className="w-[120px] h-[120px] mt-5 cursor-pointer rounded-[40px]">
              <img ref={icon7} src={darkLogo} className="w-[140px] underShadow hover:w-[150px] rounded-[40px]"/>
            </button>
            <button id="dark1" onClick={handleChooseIcon} className="w-[120px] h-[120px] mt-5 cursor-pointer rounded-[40px]">
              <img ref={icon8} src={dark1} className="w-[140px] underShadow hover:w-[150px] rounded-[40px]"/>
            </button>
            <button id="light1" onClick={handleChooseIcon} className="w-[120px] h-[120px] mt-5 cursor-pointer rounded-[40px]">
              <img ref={icon9} src={light1} className="w-[140px] underShadow hover:w-[150px] rounded-[40px]"/>
            </button>
          </section>
          </div>
          </div>
          <div className="h-20 border-t-2 pt-4 bg-white relative z-30">
          <button className="border-2 bg-[#1e4259] underShadow text-white font-bold p-2 hover:p-3 hover:text-xl rounded-md mb-5 hover:shadow-xl" onClick={() => confirmLogoChange()}>Confirm Change</button>
          </div>
        </div>
        </div>
        </div>
      )}
      
      {activeColorOne && 
      <div className="w-screen h-screen fixed bottom-0">
        <div className="w-screen h-screen fixed bg-gray-600 opacity-40"></div>
        <div className="w-11/12 md:w-96 fixed top-40 rounded-lg m-auto bg-white border-2 homeText pb-5 relative z-90">
          <div className="flex justify-between w-full bg-gray-100 border-b-2 p-3 pb-1">
            <h2 className=" font-bold ">Change Navigation Color</h2> 
            <button onClick={() => setActiveColorOne(false)} className=" w-8 h-8 font-bold text-xl hover:shadow-xl">X</button>
          </div>
          
          <div className="w-min m-auto mt-3">
            <ChromePicker color={navColor}
              onChange={c =>
                setNavColor("#" + rgbHex(c.rgb.r, c.rgb.g, c.rgb.b, c.rgb.a))
              }
            />
            <button onClick={confirmColorOneChange} className="mt-5 mainColor px-2 py-1 rounded text-white font-bold hover:shadow-xl">Confirm Change</button>
          </div>
        </div>
      </div>
      }
      {activeColorTwo && 
      <div className="w-screen h-screen fixed bottom-0">
      <div className="w-screen h-screen fixed bg-gray-600 opacity-40"></div>
        <div className="w-11/12 md:w-96 fixed top-40 md:bottom-80 rounded-lg m-auto bg-white border-2 homeText pb-5 relative z-90">
          <div className="flex justify-between w-full bg-gray-100 border-b-2 p-3 pb-1">
            <h2 className=" font-bold ">Change Top Bar & Icon Color</h2> 
            <button onClick={() => setActiveColorTwo(false)} className=" w-8 h-8 font-bold text-xl hover:shadow-xl">X</button>
          </div>
          
          <div className="w-min m-auto mt-3">
            <ChromePicker color={iconColor}
              onChange={c =>
                seticonColor("#" + rgbHex(c.rgb.r, c.rgb.g, c.rgb.b, c.rgb.a))
              }
            />
            <button onClick={confirmColorTwoChange} className="mt-5 mainColor px-2 py-1 rounded text-white font-bold hover:shadow-xl">Confirm Change</button>
          </div>
        </div>
      </div>
      }      
    </div>
  )
}

export default PhotoTheme;