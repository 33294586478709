import React, { useContext, useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import auth from "../../../utils/auth";
import { AiOutlineMenuFold } from 'react-icons/ai';
import lightLogo from '../../../assets/icons/Light-png 1.png';
import { useStoreContext } from '../../../utils/GlobalState';

function DasboardNav() {
  const [state, dispatch] = useStoreContext();
  console.log(state)
  const [selectedSection, setSelectSection] = useState('guides')
  const dashNav = useRef();

  const handleNavClick = () => {
    console.log(dashNav.current.classList);
    if(dashNav.current.classList.contains('dnavClosed')){
      dashNav.current.classList.remove('dnavClosed');
      dashNav.current.classList.add('dNavOpen');
    } else {
      dashNav.current.classList.add('dnavClosed');
      dashNav.current.classList.remove('dNavOpen');
    }
    
  }

  const handleLogout = () => {
    auth.logout();
  }

  const handleClick = (section) => {
    setSelectSection(section);
    // dashNav.current.classList.add('dnavClosed');
    // dashNav.current.classList.remove('dNavOpen');
  }



  return (
    <nav className="dashboardNav lg:w-[13%] w-full font flex max-w-[100vw]"> 
      <section className="flex flex-col w-full"> 
        <Link to="/dashboard" className="text-xl md:text-3xl w-full"><img alt='icon' className="w-48  relative bottom-2 md:bottom-3 mb-[-60px] md:mb-[-20px] mr-auto" src={lightLogo} /></Link>
        {/* <p className=" ml-[-30px] sm:hidden inline-block text-white font-bold text-3xl m-auto w-3/4 text-center">VACANZE</p> */}
        {/* <button className="text-white text-3xl ml-auto inline-block sm:hidden" onClick={handleNavClick}><AiOutlineMenuFold/></button> */}
        <div ref={dashNav} className=" w-full lg:mb-auto mt-10 md:mt-20 flex md:block">
          <div className={selectedSection === 'guides' ? ('w-full text-left bg-[#5B6778] rounded') : ("w-full text-left")}>
            <Link className="w-full block" onClick={() => handleClick('guides')} to='guides' >
              Guides
            </Link>
            {selectedSection === 'guides' &&
            (
              <div className="ml-[2px!important] md:flex hidden mr-[0px!important] p-[0!important] border-white border-l-2 border-solid flex-col max-h-[600px] overflow-y-scroll scrollbar2">
                {state?.user?.guides?.map((guide, index) => {
                return (
                <Link className="dash-sub-link p-2" key={guide._id} to={"/dashboard/guide/edit/" + guide._id}>
                  {guide.name}
                </Link>
                )})}
              </div>
            )
            }
          </div>
          {/* <div className={selectedSection === 'subsription' ? ('navUnderline hover-underline-animation') : ("hover-underline-animation")}>
            <Link onClick={() => handleClick('subsription')}  to='subsription' >
              Subscription
            </Link>
          </div> */}
          <div className={selectedSection === 'account' ? ('w-full text-left bg-[#5B6778] rounded') : ("w-full text-left")}>
            <Link className="w-full block" onClick={() => handleClick('account')}  to='account' >
              Account
            </Link>
          </div>
          {/* <div className={selectedSection === 'help' ? ('navUnderline hover-underline-animation') : ("hover-underline-animation")}>
            <Link onClick={() => handleClick('help')}  to='help' >
              Help/Tutorial
            </Link>
          </div> */}

          <div className="md:mt-auto">
            <button onClick={handleLogout} className="flex md:hidden font-bold text-white p-2 md:mx-5 md:mt-auto">Log Out</button>
          </div>
        </div>
      </section>

      <div className="h-fit mt-auto">
        <button onClick={handleLogout} className="hidden md:inline-block font-bold text-white p-2 mx-5 mt-auto">Log Out</button>
      </div>
    </nav>
  );
}

export default DasboardNav;