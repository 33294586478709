import React, { useState, useRef } from "react";
import { AiOutlineCar } from 'react-icons/ai';
import { BsWifi, BsHouse, BsKey, BsTelephone, BsBagCheck, BsStar, BsPinMap, BsController, BsBook, BsHeart, BsEmojiSmile, BsCart3, BsCurrencyDollar, BsPower, BsListUl, BsMap, BsSignpostSplit } from 'react-icons/bs';
import { FiSettings, FiTrash2, FiEye } from 'react-icons/fi';
import { BiBed, BiPlusMedical } from 'react-icons/bi';
import { GiBinoculars, GiForkKnifeSpoon, GiNightSleep, GiAirplaneDeparture, GiCoffeeCup } from 'react-icons/gi';
import { MdPets, MdOutlineWarningAmber } from 'react-icons/md';
import { useMutation } from "@apollo/client";
import { ADD_CATEGORY } from "../../../utils/mutations";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { useStoreContext } from '../../../utils/GlobalState';


const AddSection = ({setActiveAddSection, guideId}) => {
  const [state, dispatch] = useStoreContext();
  const [formState, setFormState] = useState({title:'', description: ''});

  const [value, setValue] = useState();
  const handleQuill = (e) => {
    setValue(e)
    console.log(e)
    setFormState({
      ...formState,
      description: value
    });
  }

  const handleFormChange = (event) => {
    const { name, value } = event.target;
    setFormState({
      ...formState,
      [name]: value,
    });
    console.log(formState)
  };

  const [addCategory] = useMutation(ADD_CATEGORY);
  const saveSection = async(event) => {
    const id = guideId;
    try{
      let catOrder = state?.guide?.categories?.length + 1;
      console.log(catOrder)
      const addCategoryResponse = await addCategory({
        variables: {
          name: formState.title,
          description: formState.description,
          guideId: id,
          icon: selectedOption,
          order: catOrder
        }
      });
      console.log(addCategoryResponse);
      setActiveAddSection(false);
    } catch (e){
      console.log(e);
    }
  }

  // select functionality
  // const options = [
  //   { value: 'BsWifi', label: (
  //     <div className="w-full flex justify-around">
  //       <BsWifi className="text-3xl w-1/4 h-1/4" /> 
  //     </div>
  //   )},
  //   { value: 'BsHouse', label: (
  //     <div className="w-full flex justify-around">
  //       <BsHouse className="text-3xl w-1/4 h-1/4" /> 
  //     </div>
  //   ) },
  //   { value: 'BsKey', label: (
  //     <div className="w-full flex justify-around">
  //       <BsKey className="text-3xl w-1/4 h-1/4" /> 
  //     </div>
  //   )},
  //   { value: 'BsTelephone', label: (
  //     <div className="w-full flex justify-around">
  //       <BsTelephone className="text-3xl w-1/4 h-1/4" /> 
  //     </div>
  //   )},
  //   { value: 'BsBagCheck', label: (
  //     <div className="w-full flex justify-around">
  //       <BsBagCheck className="text-3xl w-1/4 h-1/4" /> 
  //     </div>
  //   )},
  //   { value: 'BsStar', label: (
  //     <div className="w-full flex justify-around">
  //       <BsStar className="text-3xl w-1/4 h-1/4" /> 
  //     </div>
  //   )},
  //   { value: 'BsPinMap', label: (
  //     <div className="w-full flex justify-around">
  //       <BsPinMap className="text-3xl w-1/4 h-1/4" /> 
  //     </div>
  //   )},
  //   { value: 'BsBook', label: (
  //     <div className="w-full flex justify-around">
  //       <BsBook className="text-3xl w-1/4 h-1/4" /> 
  //     </div>
  //   )},
  //   { value: 'BsController', label: (
  //     <div className="w-full flex justify-around">
  //       <BsController className="text-3xl w-1/4 h-1/4" /> 
  //     </div>
  //   )},
  // ];

  const bsWifi = useRef()
  const bsHouse = useRef()
  const bsKey = useRef()
  const bsTelephone = useRef()
  const bsBagCheck = useRef()
  const bsStar = useRef()
  const bsPinMap = useRef()
  const bsController = useRef()
  const bsBook = useRef()
  const bsHeart = useRef()
  const bsEmojiSmile = useRef()
  const bsCart3 = useRef()
  const bsCurrencyDollar = useRef()
  const bsPower = useRef()
  const bsListUl = useRef()
  const bsMap = useRef()
  const bsSignpostSplit = useRef()
  const fiSettings = useRef()
  const fiTrash2 = useRef()
  const fiEye = useRef()
  const biBed = useRef()
  const biPlusMedical = useRef()
  const giBinoculars = useRef()
  const giForkKnifeSpoon = useRef()
  const giNightSleep = useRef()
  const giAirplaneDeparture = useRef()
  const mdPets = useRef()
  const mdOutlineWarningAmber = useRef()
  const aiOutlineCar = useRef()
  // const GiCoffeeCup = useRef()
  
  // const BsClock = useRef()

  let [selectedOption, setSelectedOption] = useState('BsWifi');

  function handleIconChange(e, icon){
    setSelectedOption(icon)
    // GiCoffeeCup.current.children[0].classList.remove('border-4')
    bsWifi.current.children[0].classList.remove('border-4')
    bsHouse.current.children[0].classList.remove('border-4')
    bsKey.current.children[0].classList.remove('border-4')
    bsTelephone.current.children[0].classList.remove('border-4')
    bsBagCheck.current.children[0].classList.remove('border-4')
    bsStar.current.children[0].classList.remove('border-4')
    bsPinMap.current.children[0].classList.remove('border-4')
    bsController.current.children[0].classList.remove('border-4')
    bsBook.current.children[0].classList.remove('border-4')
    bsHeart.current.children[0].classList.remove('border-4')
    bsEmojiSmile.current.children[0].classList.remove('border-4')
    bsCart3.current.children[0].classList.remove('border-4')
    bsCurrencyDollar.current.children[0].classList.remove('border-4')
    bsPower.current.children[0].classList.remove('border-4')
    bsListUl.current.children[0].classList.remove('border-4')
    bsMap.current.children[0].classList.remove('border-4')
    bsSignpostSplit.current.children[0].classList.remove('border-4')
    fiSettings.current.children[0].classList.remove('border-4')
    fiTrash2.current.children[0].classList.remove('border-4')
    fiEye.current.children[0].classList.remove('border-4')
    biBed.current.children[0].classList.remove('border-4')
    biPlusMedical.current.children[0].classList.remove('border-4')
    giBinoculars.current.children[0].classList.remove('border-4')
    giForkKnifeSpoon.current.children[0].classList.remove('border-4')
    giNightSleep.current.children[0].classList.remove('border-4')
    giAirplaneDeparture.current.children[0].classList.remove('border-4')
    mdPets.current.children[0].classList.remove('border-4')
    mdOutlineWarningAmber.current.children[0].classList.remove('border-4')
    aiOutlineCar.current.children[0].classList.remove('border-4')
    // BsClock.current.children[0].classList.remove('border-4')

    e.currentTarget.children[0].classList.add('border-4')
    e.currentTarget.children[0].classList.add('border-cyan-600')
    
  }

  return(
    // <div className="sticky w-screen h-screen m-auto top-12 left-0 z-30 text-white mb-[-550px] md:mb-[-450px]">
    <>
      <div className="bg-gray-600 opacity-30 fixed w-full h-full z-20"></div>
      <div className="w-full fixed bottom-24  left-0 z-40">
        <div className="bg-white text-black w-11/12 md:w-[800px] m-auto mt-44 rounded-xl">
        <div className="flex justify-between bg-gray-100 border-b-2 rounded-t-xl px-4 pt-2">
          <p className="font-bold text-2xl">Add A Section</p>
          <button className="relative z-80 font-bold bg-gray-100 text-2xl  rounded text-black pt-1 w-8 ml-2" onClick={() => setActiveAddSection(false)}>X</button>
        </div>
        <div className="flex flex-wrap font-bold text-left px-8 md:max-h-[600px] max-h-[400px] overflow-y-scroll border-b-2">
          <label className="w-full mt-8 flex flex-wrap md:flex-nowrap"> <p className="w-1/3"><strong className="text-gray-400">*</strong>Title:</p>
            <input name="title" onChange={handleFormChange}  placeholder="Title" className="mx-2 px-2 rounded border-2 border-gray-400 w-full md:w-2/3 text-black"/>
          </label>
          <label className="w-full mt-3 flex flex-wrap md:flex-nowrap"><p className="w-1/3">Description:<span className="text-gray-500 text-sm pl-2">(not required)</span></p> 
            {/* <textarea name="description" onChange={handleFormChange} placeholder="Description" className="text-black px-2 mx-2 rounded border-2 border-gray-400 w-full md:w-2/3"/> */}
            <ReactQuill theme="snow" value={value} onChange={handleQuill} name="description" placeholder='Description' className='w-4/5 m-auto'/>
          </label>
          <div className="flex flex-wrap justify-around w-full">
            <div className="w-full">
            <p className="w-fit m-auto mt-2"><strong className="text-gray-400">*</strong>Select an Icon for your Section: </p>
            </div>
            <div className="flex flex-wrap justify-around w-fit m-2">
            <button ref={GiCoffeeCup} onClick={(e) => handleIconChange(e,'GiCoffeeCup')} className="w-[90px] h-[90px]">
                <GiCoffeeCup id="GiCoffeeCup" className="text-7xl m-auto p-1 rounded-lg"/>
              </button>
              <button ref={bsWifi} onClick={(e) => handleIconChange(e,'BsWifi')} className="w-[90px] h-[90px]">
                <BsWifi id="BsWifi" className="text-7xl m-auto p-1 rounded-lg"/>
              </button>
              <button ref={aiOutlineCar} onClick={(e) => handleIconChange(e,'AiOutlineCar')} className="w-[90px] h-[90px]">
                <AiOutlineCar id="AiOutlineCar" className="text-7xl m-auto p-1 rounded-lg"/>
              </button>
              <button ref={bsHouse} onClick={(e) => handleIconChange(e,'BsHouse')} className="w-[90px] h-[90px]">
                <BsHouse id="BsHouse" className="text-7xl m-auto p-1 rounded-lg"/>
              </button>
              <button ref={bsKey} onClick={(e) => handleIconChange(e,'BsKey')} className="w-[90px] h-[90px]">
                <BsKey id="BsKey" className="text-7xl m-auto  p-1 rounded-lg"/>
              </button>
              <button ref={bsTelephone} onClick={(e) => handleIconChange(e,'BsTelephone')} className="w-[90px] h-[90px]">
                <BsTelephone id="BsTelephone" className="text-7xl m-auto p-1 rounded-lg"/>
              </button>
              <button ref={bsBagCheck} onClick={(e) => handleIconChange(e,'BsBagCheck')} className="w-[90px] h-[90px]">
                <BsBagCheck id="BsBagCheck" className="text-7xl m-auto p-1 rounded-lg"/>
              </button>
              <button ref={bsStar} onClick={(e) => handleIconChange(e,'BsStar')} className="w-[90px] h-[90px]">
                <BsStar id="BsStar" className="text-7xl m-auto p-1 rounded-lg"/>
              </button>
              <button ref={bsPinMap} onClick={(e) => handleIconChange(e,'BsPinMap')} className="w-[90px] h-[90px]">
                <BsPinMap id="BsPinMap" className="text-7xl m-auto  p-1 rounded-lg"/>
              </button>
              <button ref={bsController} onClick={(e) => handleIconChange(e,'BsController')} className="w-[90px] h-[90px]">
                <BsController id="BsController" className="text-7xl m-auto p-1 rounded-lg"/>
              </button>
              <button ref={bsBook} onClick={(e) => handleIconChange(e,'BsBook')} className="w-[90px] h-[90px]">
                <BsBook id="BsBook" className="text-7xl m-auto p-1 rounded-lg"/>
              </button>
              <button ref={bsHeart} onClick={(e) => handleIconChange(e,'BsHeart')} className="w-[90px] h-[90px]">
                <BsHeart id="BsHeart" className="text-7xl m-auto p-1 rounded-lg"/>
              </button>
              <button ref={bsEmojiSmile} onClick={(e) => handleIconChange(e,'BsEmojiSmile')} className="w-[90px] h-[90px]">
                <BsEmojiSmile id="BsEmojiSmile" className="text-7xl m-auto  p-1 rounded-lg"/>
              </button>
              <button ref={giAirplaneDeparture} onClick={(e) => handleIconChange(e,'GiAirplaneDeparture')} className="w-[90px] h-[90px]">
                <GiAirplaneDeparture id="GiAirplaneDeparture" className="text-7xl m-auto p-1 rounded-lg"/>
              </button>
              <button ref={bsCart3} onClick={(e) => handleIconChange(e,'BsCart3')} className="w-[90px] h-[90px]">
                <BsCart3 id="BsCart3" className="text-7xl m-auto p-1 rounded-lg"/>
              </button>
              <button ref={bsCurrencyDollar} onClick={(e) => handleIconChange(e,'BsCurrencyDollar')} className="w-[90px] h-[90px]">
                <BsCurrencyDollar id="BsCurrencyDollar" className="text-7xl m-auto p-1 rounded-lg"/>
              </button>
              <button ref={bsListUl} onClick={(e) => handleIconChange(e,'BsListUl')} className="w-[90px] h-[90px]">
                <BsListUl id="BsListUl" className="text-7xl m-auto  p-1 rounded-lg"/>
              </button>
              <button ref={bsPower} onClick={(e) => handleIconChange(e,'BsPower')} className="w-[90px] h-[90px]">
                <BsPower id="BsPower" className="text-7xl m-auto p-1 rounded-lg"/>
              </button>
              <button ref={bsMap} onClick={(e) => handleIconChange(e,'BsMap')} className="w-[90px] h-[90px]">
                <BsMap id="BsMap" className="text-7xl m-auto p-1 rounded-lg"/>
              </button>
              <button ref={bsSignpostSplit} onClick={(e) => handleIconChange(e,'BsSignpostSplit')} className="w-[90px] h-[90px]">
                <BsSignpostSplit id="BsSignpostSplit" className="text-7xl m-auto p-1 rounded-lg"/>
              </button>
              <button ref={fiSettings} onClick={(e) => handleIconChange(e,'FiSettings')} className="w-[90px] h-[90px]">
                <FiSettings id="FiSettings" className="text-7xl m-auto p-1 rounded-lg"/>
              </button>
              <button ref={fiTrash2} onClick={(e) => handleIconChange(e,'FiTrash2')} className="w-[90px] h-[90px]">
                <FiTrash2 id="FiTrash2" className="text-7xl m-auto p-1 rounded-lg"/>
              </button>
              <button ref={fiEye} onClick={(e) => handleIconChange(e,'FiEye')} className="w-[90px] h-[90px]">
                <FiEye id="FiEye" className="text-7xl m-auto p-1 rounded-lg"/>
              </button>
              <button ref={biBed} onClick={(e) => handleIconChange(e,'BiBed')} className="w-[90px] h-[90px]">
                <BiBed id="BiBed" className="text-7xl m-auto p-1 rounded-lg"/>
              </button>
              <button ref={biPlusMedical} onClick={(e) => handleIconChange(e,'BiPlusMedical')} className="w-[90px] h-[90px]">
                <BiPlusMedical id="BiPlusMedical" className="text-7xl m-auto p-1 rounded-lg"/>
              </button>
              <button ref={giBinoculars} onClick={(e) => handleIconChange(e,'GiBinoculars')} className="w-[90px] h-[90px]">
                <GiBinoculars id="GiBinoculars" className="text-7xl m-auto p-1 rounded-lg"/>
              </button>
              <button ref={giForkKnifeSpoon} onClick={(e) => handleIconChange(e,'GiForkKnifeSpoon')} className="w-[90px] h-[90px]">
                <GiForkKnifeSpoon id="GiForkKnifeSpoon" className="text-7xl m-auto p-1 rounded-lg"/>
              </button>
              <button ref={giNightSleep} onClick={(e) => handleIconChange(e,'GiNightSleep')} className="w-[90px] h-[90px]">
                <GiNightSleep id="GiNightSleep" className="text-7xl m-auto p-1 rounded-lg"/>
              </button>              
              <button ref={mdPets} onClick={(e) => handleIconChange(e,'MdPets')} className="w-[90px] h-[90px]">
                <MdPets id="MdPets" className="text-7xl m-auto p-1 rounded-lg"/>
              </button>
              <button ref={mdOutlineWarningAmber} onClick={(e) => handleIconChange(e,'MdOutlineWarningAmber')} className="w-[90px] h-[90px]">
                <MdOutlineWarningAmber id="MdOutlineWarningAmber" className="text-7xl m-auto p-1 rounded-lg"/>
              </button>    
              {/* <button ref={BsClock} onClick={(e) => handleIconChange(e,'BsClock')} className="w-[90px] h-[90px]">
                <BsClock id="BsClock" className="text-7xl m-auto p-1 rounded-lg"/>
              </button>           */}
              {/* <Select
            name="icon"
            className="text-black w-full "
            defaultValue={selectedOption}
            onChange={setSelectedOption}
            options={options}
            /> */}
            </div>
            

          </div>
          
        </div>
        <button className="dashboardBtn" onClick={saveSection}>Save Section</button>
      </div>
      </div>
      {/* </div> */}
      </>
    
  )
};

export default AddSection;