import React, { useState, useRef } from "react";
import { Link } from "react-router-dom";
import { useStoreContext } from '../../../utils/GlobalState';
import { BsShareFill } from "react-icons/bs";
import QRCodeModal from "./qrCode";
import NoGuides from "./noGuides";
import edit from '../../../assets/edit.png';
import share from '../../../assets/share.png';
import code from '../../../assets/code.png';
import open from '../../../assets/open.png';


function GuidesPreview() {
  const [state, dispatch] = useStoreContext();
  // console.log(state?.user?.subscriptionStatus)
  const subStatus = state?.user?.subscriptionStatus === 'active';
  // console.log(subStatus)
  let blur = {
    filter: 'blur(0px)'
  }

  subStatus ?(
    blur = { filter: 'blur(0px)' }
  ) : (
    blur = { filter: 'blur(3px)',
    pointerEvents: 'none'
    }
  )
  

  const shareMenu = useRef();

  const [visibleShareNav, setVisibleShareNav] = useState('false');

  const [currentShareGuide, setCurrentShareGuide] = useState(state?.user?.guides[0]);

  const openNav = (trueOrFalse, guide) => {
    setVisibleShareNav(trueOrFalse);
    setCurrentShareGuide(guide);
  }



  // UPDATE THE LINK THAT IS COPIED
  const PopluateShareNav = (activeGuide) => {
    const guide = activeGuide.guide;
    return (
      <div className={visibleShareNav === 'false' ? " w-screen h-screen absolute top-0 right-0 hidden" : 'sticky bottom-0 h-screen w-screen'}>
        <div className="bg-gray-700 opacity-30 absolute md:relative top-0 right-0 md:right-40 w-full h-full m-auto"></div>
      <nav className={visibleShareNav === 'false' ? "hiddenShareMenu" : 'shareMenu'}>
        {currentShareGuide && (
          <>
            <div className="w-full flex rounded-t-xl justify-between px-4 py-2 text-black font-bold bg-gray-100 border-b-2">
              <h3 className="text-2xl text-black text-left">Share <strong>{guide.name} </strong>Guide Via..</h3>
              <button onClick={() => setVisibleShareNav('false')} className=" font-bold text-xl text-center h-8 w-8 rounded pt-1">X</button>
          </div>
          
            <button onClick={() => copy(guide._id)} className="mainColor text-white w-full p-2 text-xl font-bold m-3 linkShadow rounded">Copy Link</button>
            <a href={`mailto:?to=&body=Check out this guide to ${guide.name}: https://www.vacanzeguides.com/user/${state?.user?._id}/guide/${guide._id}&subject=Check out this guide to ${guide.name}`} className="mainColor text-white w-full p-1 pt-2 text-xl font-bold m-3 linkShadow rounded">Email</a>
            
            <a href={`sms://&body=Check out this guide to ${guide.name}: https://www.vacanzeguides.com/user/${state?.user?._id}/guide/${guide._id}`} className="mainColor text-white w-full p-1 text-xl font-bold m-3 linkShadow rounded pt-2">Text</a>
            
          </>
        )}
      </nav>
      </div>
    )
  }


  const [confirmCopy, setConfirmCopy] = useState('false');
  const copy = (guideId) => {
    const url = `https://www.vacanzeguides.com/user/${state?.user?._id}/guide/${guideId}`;
    navigator.clipboard.writeText(url);
    setConfirmCopy('true');
  }

  const [url, setUrl] = useState('');
  const [display, setDisplay] = useState('none');
  const [qrGuide, setQrGuide] = useState();

  const setUrlAndDisplay = (guide) => {
    setUrl(`https://www.vacanzeguides.com/user/${state?.user?._id}/guide/${guide._id}`);
    setDisplay('active');
    setQrGuide(guide)
  }

  const [hideSubPopup, setHideSubPopup] = useState(false);

  return (
    <>
    
    {state.user && state.user.guides.length ? (
    <div className="w-full h-max">
      {state.user.guides.map((guide, index) => {
        // if(index-1 > state.user.subscriptionName){
        //   console.log('hi')
        // }
        return (
        <div className="p-5 font" id={guide._id} key={guide._id}>
        <div className="preview h-fit pb-8 md:pb-0 flex flex-wrap md:flex-nowrap w-11/12 m-auto bg-white rounded guideShadow">
            <img src={guide.photo} alt='preview' className="rounded-l h-full w-full md:w-2/5 md:max-w-[15%] lg:w-min object-cover"></img>
          <div className="w-full flex flex-wrap md:flex-nowrap my-auto" >
          
            <div className="flex flex-col justify-center mx-4 md:mx-0 md:ml-4 text-xl mt-3 text-left font"> 
              <p id="title" className=" w-full font-bold text-4xl m-0 mb-1 md:mb-3 ">{guide.name}</p>
              <p id="city" className=" mb-4 md:mb-4 text-xl font-medium">{guide.address}</p>
            </div>
            <div className="w-full md:w-2/3 flex flex-wrap justify-around text-xl h-3/5 ml-auto">
              <div className="w-full flex flex-wrap justify-around md:pb-5 mb-0 h-fit mt-6">
                <div className="w-[120px] blueGradientBG p-3 rounded-lg hover:shadow-xl cursor-pointer">
                  <Link to={"/user/" + state?.user?._id + "/guide/" + guide._id} target="blank" id={guide._id} className="hover:shadow-xl mainColor" style={blur}>
                  <img src={open} />
                  <p className="text-white">See Guide</p>
                  </Link>
                </div>
                <div className="w-[120px] blueGradientBG p-3 rounded-lg hover:shadow-xl cursor-pointer">
                <Link to={"/dashboard/guide/edit/" + guide._id} id="edit" className=" mainColor" style={blur}>
                  <img src={edit} />
                  <p className="text-white">Edit Guide</p>
                  </Link>
                </div>
              
                    <button onClick={() => {setUrlAndDisplay(guide)}} className="h-full w-[120px] md:mb-0 mb-4 p-3 px-4 h-min blueGradientBG hover:shadow-xl text-white rounded-lg" style={blur}>
                    <img className="flex m-auto" src={code} />
                      <p className="pt-2"> See QR Code</p>
                      </button>
                  <div className="h-min relative">
                    <button onClick={() => openNav('true', guide)} id="invite/share" className="flex flex-col w-[120px] font p-3 px-4 h-min hover:shadow-xl  blueGradientBG text-white rounded-lg flex" style={blur}>
                    <img className="flex m-auto" src={share} />
                      <p className="pt-2">Share Guide</p>
                    </button>
                  </div>

              </div>
            </div>
          </div>
        </div>
      </div>
      )})} 
      <PopluateShareNav guide={currentShareGuide} />
      <QRCodeModal  url={url} display={display} setDisplay={setDisplay} guide={qrGuide}/>
    </div>
    ):(<NoGuides />)}
    
    {/* {(subStatus !== 'active' & hideSubPopup & (state?.user?.guides?.length > 0)) ?  (
      <div></div>
      ):(
      <div className="w-80 md:w-96 flex flex-wrap border-2 border-gray-500 bg-white guideShadow rounded-md absolute top-48 md:top-16 left-8 md:left-1/2">
        <div className="flex flex-wrap align-center my-auto pb-6 pt-4">
          <h1 className="pb-3 w-full">Your subsription is not currently active, activate your subsription to see & share your guides.</h1>
        <button onClick={() => setHideSubPopup(false)} className="p-3 pt-4 secondColor homeText rounded-lg m-auto">Close</button>
        <Link to='/dashboard/addSubsription' className="p-3 pt-4 mainColor text-white rounded-lg m-auto">Manage Subscription</Link>
        </div>
      </div>
    )} */}
    
    </>
  );
};

export default GuidesPreview;