import React, { useState, useRef, useEffect } from "react";
import { UPDATE_GUIDE_PHONE } from "../../../utils/mutations";
import { useMutation } from "@apollo/client";
import { formatPhoneNumber } from "../../../utils/formatPhone";
import editGray from '../../../assets/edit-gray.png'

import { useStoreContext } from '../../../utils/GlobalState';

const EditPhone = (props) => {
  // console.log(props)
  const [activeEdit, setActiveEdit] = useState(false)  
  const [phone, setPhone] = useState(props.guidePhone)
  
  const [state, dispatch] = useStoreContext();
  const guideId = state.guide?.guideId;
  // console.log(state?.guide)

  const [updateGuidePhone] = useMutation(UPDATE_GUIDE_PHONE);

  const phoneRef = useRef();

  const [inputValue, setInputValue] = useState('');
  const handlePhoneInput = e => {
    const formattedPhone = formatPhoneNumber(e.target.value);
    setInputValue(formattedPhone);
  }

  const handleEditPhone = async() => {
    // console.log(phoneRef.current.value)
    let phoneNum = formatPhoneNumber(phoneRef.current.value)
    // console.log(phoneNum)
    const updatedGuidePhone = await updateGuidePhone({
      variables: {
        guideId: props.guideId,
        contactPhone: inputValue
      }
    })

    let updatedNumer = updatedGuidePhone.data.updateGuidePhone.contactPhone;
    if(updatedNumer == inputValue){
      setActiveEdit(false)
    }

  }
  useEffect(() => {
    setPhone(formatPhoneNumber(props.guidePhone))
  }, [props]);



  return (
    <div className="">
    {activeEdit ? (<div className=" flex flex-wrap justify-between">
      <input ref={phoneRef} type="text" onChange={handlePhoneInput} name='phone' placeholder="(800) 248-6789" maxLength={15} className=" font-bold border-[1px] border-gray-200 text-black mx-2 p-2 rounded shadow w-full md:w-1/2" 
          onKeyPress={(event) => {
            if (!/[0-9]/.test(event.key)) {
              event.preventDefault();
            }
          }} 
          value={inputValue}
          />
          <button onClick={() => handleEditPhone()} className="dashboardBtn">
            Save Change
          </button>
    </div>):(
      <div className="flex flex-wrap align-center">
        <p className="flex mr-3">{phone}</p>
        <button onClick={() => setActiveEdit(true)} className=""><img src={editGray} className="w-[25px] h-[25px]"/></button>
      </div>
      )}
    
    </div>
  )
}

export default EditPhone;